import VerificationEmail from '../modules/Auth/Verification/VerificationEmail';
import { Helmet } from 'react-helmet';
import React from 'react';

interface Props {}

const EmailVerification = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Email verification</title>
			</Helmet>
			<VerificationEmail />
		</>
	);
};

export default EmailVerification;
