import clsx from 'clsx';
import React from 'react';
import Select from 'react-select';

type Props = {
	options: { value: string | number; label: string }[];
	error?: string | boolean;
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const AppSelect = ({ error, ...props }: Props) => {
	return (
		<div className={clsx('app-input text-left w-full', { empty: !props.value }, props.className)}>
			<div className="relative">
				<Select
					{...props}
					className={clsx('border border-solid ', {
						'border-red-400': !!error,
					})}
					id="country"
				></Select>
			</div>
			{error && <p className="font-bold text-red-400">{error}</p>}
		</div>
	);
};

export default AppSelect;
