import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import Loader from '../../../components/Loader/Loader';
import { authStoreContext } from '../../../stores/AuthStore';
import { AuthApi } from '../../../services/api/Auth';
import { useQueryParam } from '../../../utils/queryParams';
import VerificationForm from '../VerificationForm/VerificationForm';
import useStartPagePath from '../../../hooks/useStartPagePath';

const VerificationEmail = () => {
	const { confirmEmail } = useContext(authStoreContext);
	const email = useQueryParam('email');
	const confirmationCode = useQueryParam('confirmationCode');

	const [isSending, setIsSending] = useState(false);
	const [error, setError] = useState('');
	const [isVerified, setIsVerified] = useState(false);
	const startPagePath = useStartPagePath();

	useEffect(() => {
		if (!email || !confirmationCode) return;

		confirmEmail({
			email,
			confirmationCode,
		})
			.then(() => {
				setIsVerified(true);
			})
			.catch((error) => setError(error.description))
			.finally(() => {
				setIsSending(true);
			});
	}, [email, confirmationCode]);

	const handleSetProfile = (data) => {
		AuthApi.changeProfileData(data)
			.then(() => navigate(startPagePath))
			.catch((error) => setError(error.description));
	};

	if (!isSending) {
		return <Loader text="Verification email" />;
	}

	return (
		<div className="centered">
			<div className="container flex item-center justify-center">
				<div className="flex flex-col gap-4 max-w-md text-center">
					{isVerified ? (
						<>
							<h1 className="text-4xl">Verification successful</h1>

							<p className="text-sm">
								Your email <span className="underline decoration-blue-500 font-bold">{email}</span>
							</p>

							<VerificationForm handleSetProfile={handleSetProfile} />
						</>
					) : (
						<p className="text-lg">Verification failed. Try one more time</p>
					)}
					{error && <p className=" text-red-400">{error}</p>}
				</div>
			</div>
		</div>
	);
};

export default VerificationEmail;
