import { Link } from 'gatsby';
import React, { useMemo, useState } from 'react';
import { AppButton } from '../../../components/core/AppButton';
import AppInput from '../../../components/core/AppInput/AppInput';
import AppSelect from '../../../components/core/AppSelect';
import { countriesList } from '../../../utils/countries';

const VerificationForm = ({ handleSetProfile }) => {
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [country, setCountry] = useState<{ value: string; label: string } | undefined>(undefined);

	const isStrong = useMemo(() => {
		return !password || password.length > 5;
	}, [password]);

	const isMatch = useMemo(() => {
		return !confirmPassword || password === confirmPassword;
	}, [password, confirmPassword]);

	const disabled = useMemo(() => {
		return !isStrong || !isMatch || !country;
	}, [isStrong, isMatch]);

	const handleSelectCountry = (selectedOption) => {
		setCountry(selectedOption);
	};

	const handleSubmit = () => {
		handleSetProfile({ password, country: country?.value });
	};

	return (
		<>
			<AppSelect
				onChange={handleSelectCountry}
				value={country}
				options={countriesList.map((country) => ({ value: country, label: country }))}
				placeholder="Select your country"
				name="country"
			/>
			<p className="text-sm">Type your new password</p>
			<AppInput
				onChange={(e) => setPassword(e.target.value)}
				value={password}
				type="password"
				placeholder="Password"
				error={!isStrong ? 'Minimum 5 symbols' : undefined}
			></AppInput>
			<AppInput
				onChange={(e) => setConfirmPassword(e.target.value)}
				value={confirmPassword}
				type="password"
				placeholder="Password Confirm"
				error={!isMatch ? 'Passwords not match' : undefined}
			></AppInput>
			<p className="text-center">
				Already has account? <Link to="/login">Log in</Link>
			</p>
			<AppButton disabled={disabled} onClick={handleSubmit}>
				Set data
			</AppButton>
		</>
	);
};

export default VerificationForm;
